import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { t } from 'i18n'
import axios from 'axios'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import moment from 'moment'
import { SignDocumentModal } from './SignDocumentModal'
import { NoticeSection } from './shared'
import Notice from '../utilityComponents/Notice'
import InputWrapper from '../utilityComponents/InputWrapper'

const scope = 'components.inheritance_claims'

const NATURAL_PERSON = 'natural_person'
const LEGAL_PERSON = 'legal_person'

const deepTransformObjectKeys = (object, transformFunction) => {
  if (!object) return object

  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      const valueIsObject = typeof value === 'object'
      const newValue = valueIsObject ? deepTransformObjectKeys(value, transformFunction) : value

      return [transformFunction(key), newValue]
    })
  )
}

const ClaimForm = ({
  newInheritanceClaimTemplate,
  inheritanceClaim,
  isEditing = false
}) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [representationTypeOptions, setRepresentationTypeOptions] = useState([])
  const [showSignDocumentModal, setShowSignDocumentModal] = useState(false)
  const [notice, setNotice] = useState({ text: null, error: false })
  const [claimSelectOptions, setClaimSelectOptions] = useState({
    claimType: [],
    claimJustification: []
  })

  const inheritanceClaimPersisted = !!inheritanceClaim?.id
  const formDisabled = !isEditing && inheritanceClaimPersisted

  const isDraft = inheritanceClaim?.status === 'DRAFT'
  const isSubmitted = inheritanceClaim?.status === 'SUBMITTED'
  const isPtn = inheritanceClaim?.status === 'PTN'

  const prefilledInheritanceClaim = inheritanceClaimPersisted
    ? inheritanceClaim
    : newInheritanceClaimTemplate

  const initialValues = {
    matterId: prefilledInheritanceClaim.matterId,
    creditorType: prefilledInheritanceClaim.creditorType,
    creditorsPersonalCode: prefilledInheritanceClaim.creditorsPersonalCode,
    creditorsFirstName: prefilledInheritanceClaim.creditorsFirstName,
    creditorsLastName: prefilledInheritanceClaim.creditorsLastName,
    creditorsRegistrationNumber: prefilledInheritanceClaim.creditorsRegistrationNumber,
    creditorsLegalName: prefilledInheritanceClaim.creditorsLegalName,
    creditorsResidentialAddress: prefilledInheritanceClaim.creditorsResidentialAddress,
    creditorsLegalAddress: prefilledInheritanceClaim.creditorsLegalAddress,
    creditorsEmail: prefilledInheritanceClaim.creditorsEmail,
    creditorsPhone: prefilledInheritanceClaim.creditorsPhone,
    documentNumber: prefilledInheritanceClaim.documentNumber,
    claimType: prefilledInheritanceClaim.claimType,
    claimJustification: prefilledInheritanceClaim.claimJustification,
    claimDynamicFields: deepTransformObjectKeys(prefilledInheritanceClaim.claimDynamicFields, camelCase),
    claimAmount: prefilledInheritanceClaim.claimAmount,
    description: prefilledInheritanceClaim.description,
    representationType: prefilledInheritanceClaim.representationType,
    termsConfirmed: (inheritanceClaimPersisted && !isEditing) ? true : false
  }

  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    fetchClaimFields()
  }, [])

  const showNotice = ({ text, error = false }) => {
    setNotice({ text, error })

    setTimeout(() => {
      setNotice({ text: null, error: false })
    }, 5000)
  }

  const fetchClaimFields = () => {
    axios.get('/inheritance_claims/get_claim_fields').then(({ data }) => {
      const flattenedClaimTypes = flattenSelectOptionsWithDepth(data.claim_type)
      const flattenedClaimJustifications = flattenSelectOptionsWithDepth(data.claim_justification)

      setClaimSelectOptions({
        claimType: flattenedClaimTypes,
        claimJustification: flattenedClaimJustifications
      })

      setRepresentationTypeOptions(data.representations)
    }).catch(({ response }) => {
      console.log(response)
    })
  }

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target
    const fieldValue = type === 'checkbox' ? checked : value

    setFormValues({
      ...formValues,
      [name]: fieldValue
    })
  }

  const handleRepresentationChange = (value) => {
    setFormValues({
      ...formValues,
      representationType: value
    })
  }

  const handleSubmit = () => {
    setLoading(true)
    setErrors({})

    const editingClaimId = isEditing ? { id: inheritanceClaim.id } : {}

    const creditorTypeFields = {
      natural_person: {
        creditors_personal_code: formValues.creditorsPersonalCode,
        creditors_first_name: formValues.creditorsFirstName,
        creditors_last_name: formValues.creditorsLastName,
        creditors_residential_address: formValues.creditorsResidentialAddress
      },
      legal_person: {
        creditors_registration_number: formValues.creditorsRegistrationNumber,
        creditors_legal_name: formValues.creditorsLegalName,
        creditors_legal_address: formValues.creditorsLegalAddress
      }
    }[formValues.creditorType]

    const inheritanceClaimFields = {
      ...editingClaimId,
      ...creditorTypeFields,
      matter_id: formValues.matterId,
      document_number: formValues.documentNumber,
      claim_type: formValues.claimType,
      claim_justification: formValues.claimJustification,
      claim_amount: formValues.claimAmount,
      description: formValues.description,
      terms_confirmed: formValues.termsConfirmed,
      creditor_type: formValues.creditorType,
      creditors_email: formValues.creditorsEmail,
      creditors_phone: formValues.creditorsPhone,
      representation_type: formValues.representationType,
      claim_dynamic_fields: deepTransformObjectKeys(formValues.claimDynamicFields, snakeCase)
    }

    axios.post('/inheritance_claims/submit_claim.json', {
      inheritance_claim: inheritanceClaimFields
    }).then(({ data }) => {
      location = `/inheritance_claims/${data.id}`
    }).catch(({ response }) => {
      setErrors(response.data)
      showNotice({ text: t('project_submission_failed', { scope }), error: true })
    }).finally(() => {
      setLoading(false)
    })
  }

  const renderCreditorTypeOption = (type) => {
    return (
      <label className='mr-4'>
        <input
          type='radio'
          className='mr-2'
          name='creditorType'
          value={type}
          checked={formValues.creditorType === type}
          onChange={handleChange}
        />
        <span>{t(type, { scope })}</span>
      </label>
    )
  }

  // Place tree into single array with 'depth' property
  const flattenSelectOptionsWithDepth = (options, depth = 0, flattenedOptions = []) => {
    options.map(opt => {
      flattenedOptions.push({ ...opt, depth: depth })

      if (opt.children && opt.children.length > 0) {
        flattenSelectOptionsWithDepth(opt.children, depth + 1, flattenedOptions)
      }
    })

    return flattenedOptions
  }

  const handleClaimSelectChange = (claimField, code) => {
    const dynamicFields = {}
    claimSelectOptions[claimField].find(opt => opt.code === code)?.fields?.forEach(field => {
      dynamicFields[field.name] = ''
    })

    setFormValues({
      ...formValues,
      [claimField]: code,
      claimDynamicFields: {
        ...formValues.claimDynamicFields,
        [claimField]: dynamicFields
      }
    })
  }

  const renderClaimSelect = (field) => {
    const options = claimSelectOptions[field]
    const selectedOption = options.find(opt => opt.code === formValues[field])

    const snakeCaseField = snakeCase(field)

    return (
      <InputWrapper errorArray={errors[snakeCaseField]}>
        <label className='control-label required'>{t(snakeCaseField, { scope })}</label>
        <Select
          classNamePrefix='react-select'
          className={`react-select ${field}`}
          name={field}
          options={options}
          isOptionDisabled={opt => !opt.selectable}
          getOptionLabel={opt => opt.name}
          getOptionValue={opt => opt.code}
          styles={{ option: (styles, { data }) => {
            return { ...styles, paddingLeft: `${14 + data.depth * 20}px` }
          }}}
          clearable={false}
          value={selectedOption}
          placeholder={t(`prompts.${snakeCaseField}`, { scope })}
          onChange={({ code }) => handleClaimSelectChange(field, code)}
          isDisabled={formDisabled}
        />
      </InputWrapper>
    )
  }

  const getClaimDynamicFields = (claimSelectField) => {
    const selectedOption = claimSelectOptions[claimSelectField].find(opt =>
      opt.code === formValues[claimSelectField]
    )

    return selectedOption?.fields || []
  }

  const renderDynamicField = (scope, field) => {
    const DEFAULT_MAX_LENGTH = 4000 // NIS default varchar limit
    const { name, type, maxLength } = field

    const handleDynamicFieldChange = (value) => {
      setFormValues({
        ...formValues,
        claimDynamicFields: {
          ...formValues.claimDynamicFields,
          [scope]: {
            ...formValues.claimDynamicFields[scope],
            [name]: value
          }
        }
      })
    }

    const fieldValue = formValues.claimDynamicFields[scope][name]

    switch(type) {
      case 'date': return (
        <DatePicker
          className='form-control'
          name={name}
          selected={fieldValue ? moment(fieldValue) : null}
          onChange={date => handleDynamicFieldChange(date.format('YYYY-MM-DD'))}
        />
      )
      case 'string':
        return <>
          <input
            type='text'
            className='form-control'
            name={name}
            value={fieldValue || ''}
            maxLength={maxLength}
            onChange={({ target: { value } }) => handleDynamicFieldChange(value)}
          />
          {maxLength && maxLength < DEFAULT_MAX_LENGTH &&
            <span className='help-block hint right'>
              {`${fieldValue?.length || 0}/${maxLength}`}
            </span>
          }
        </>
      case 'number': return (
        <input
          type='number'
          className='form-control'
          name={name}
          value={fieldValue || ''}
          onChange={({ target: { value } }) => handleDynamicFieldChange(value)}
        />
      )
    }
  }

  const renderRepresentationTypeRadioButtons = () => {
    return (
      <InputWrapper errorArray={errors.representation_type}>
        <div>
          {representationTypeOptions.map(option => {
            return (
              <label className='mr-4 mb-0' key={option.code}>
                <input
                  type='radio'
                  className='mr-2'
                  name='representationType'
                  value={option.code}
                  checked={formValues.representationType === option.code}
                  onChange={() => handleRepresentationChange(option.code)}
                />
                <span>{option.name}</span>
              </label>
            )
          })}
        </div>
      </InputWrapper>
    )
  }

  const renderInputField = (name, { showLabel = true, required = true } = {}) => {
    const snakeCaseField = snakeCase(name)

    return (
      <InputWrapper errorArray={errors[snakeCaseField]}>
        {showLabel &&
          <label className={`control-label ${required ? 'required' : ''}`}>
            {t(snakeCaseField, { scope })}
          </label>
        }
        <input
          type='text'
          className='form-control'
          name={name}
          placeholder={inheritanceClaimPersisted ? '-' : t(`prompts.${snakeCaseField}`, { scope })}
          value={formValues[name]}
          onChange={handleChange}
        />
      </InputWrapper>
    )
  }

  const renderNaturalPersonInputs = () => {
    return (
      <>
        <InputWrapper errorArray={errors.creditors_personal_code}>
          <label className='control-label required'>{t('creditors_personal_code', { scope })}</label>
          <InputMask
            type='text'
            className='form-control'
            name='creditorsPersonalCode'
            placeholder={t('prompts.creditors_personal_code', { scope })}
            value={formValues.creditorsPersonalCode}
            mask={'999999-99999'}
            onChange={handleChange}
          />
        </InputWrapper>
        <div className='row'>
          <div className='col-md-6'>
            {renderInputField('creditorsFirstName')}
          </div>
          <div className='col-md-6'>
            {renderInputField('creditorsLastName')}
          </div>
        </div>
        {renderInputField('creditorsResidentialAddress')}
      </>
    )
  }

  const renderLegalPersonInputs = () => {
    return (
      <>
        <InputWrapper errorArray={errors.creditors_registration_number}>
          <label className='control-label required'>{t('creditors_registration_number', { scope })}</label>
          <InputMask
            type='text'
            className='form-control'
            name='creditorsRegistrationNumber'
            placeholder={t('prompts.creditors_registration_number', { scope })}
            value={formValues.creditorsRegistrationNumber}
            mask={'99999999999'}
            onChange={handleChange}
          />
        </InputWrapper>
        {renderInputField('creditorsLegalName')}
        {renderInputField('creditorsLegalAddress')}
      </>
    )
  }

  const renderSignableDocumentButtons = () => {
    return <>
      <a
        className='btn form-submit-button secondary mr-3'
        href={`/inheritance_claims/${inheritanceClaim.id}/view_claim_document`}
        target='_blank'
        disabled={!formValues.termsConfirmed}
      >
        {t('preview_document', { scope })}
      </a>
      <button
        className='btn form-submit-button'
        onClick={() => setShowSignDocumentModal(true)}
        disabled={!formValues.termsConfirmed || isSubmitted}
      >
        {isSubmitted
          ? t('document_submitted', { scope })
          : t('sign_and_submit_document', { scope })
        }
      </button>
    </>
  }

  const renderSaveProjectButton = () => {
    return (
      <button
        className='btn form-submit-button'
        onClick={handleSubmit}
        disabled={!formValues.termsConfirmed || loading}
      >
        {t('save_project', { scope })}
      </button>
    )
  }

  const renderNoticeSection = () => {
    if (isDraft) {
      return <NoticeSection className='info' message={t('notice.project_saved', { scope })} />
    } else if (isEditing) {
      return <NoticeSection className='info' message={t('notice.editing', { scope })} />
    } else if (isSubmitted) {
      return <NoticeSection className='success' message={t('notice.project_submitted', { scope })} />
    }
  }

  return (
    <div className='service-form col-sm-8 col-md-6 col-lg-5 m-auto py-5'>
      {renderNoticeSection()}
      <fieldset disabled={formDisabled}>
        <h6 className='mb-3'>{t('creditor', { scope })}</h6>
        <div className='mb-2'>
          {renderCreditorTypeOption(NATURAL_PERSON)}
          {renderCreditorTypeOption(LEGAL_PERSON)}
        </div>

        {formValues.creditorType === NATURAL_PERSON &&
          renderNaturalPersonInputs()
        }

        {formValues.creditorType === LEGAL_PERSON &&
          renderLegalPersonInputs()
        }

        {renderInputField('creditorsEmail')}
        {renderInputField('creditorsPhone')}

        <h6 className='mb-3'>{t('claim', { scope })}</h6>

        {renderInputField('documentNumber', { required: false })}

        {renderClaimSelect('claimType')}
        {getClaimDynamicFields('claimType').map(field =>
          <InputWrapper errorArray={errors[snakeCase(field.name)]} key={field.name}>
            <label className={`control-label ${field.required ? 'required' : ''}`}>
              {field.title}
            </label>
            {renderDynamicField('claimType', field)}
          </InputWrapper>
        )}

        {renderClaimSelect('claimJustification')}
        {getClaimDynamicFields('claimJustification').map(field =>
          <InputWrapper errorArray={errors[snakeCase(field.name)]} key={field.name}>
            <label className={`control-label ${field.required ? 'required' : ''}`}>
              {field.title}
            </label>
            {renderDynamicField('claimJustification', field)}
          </InputWrapper>
        )}

        {renderInputField('claimAmount')}

        <InputWrapper errorArray={errors.description}>
          <label className='control-label'>
            {t('description', { scope })}
          </label>
          <textarea
            rows='3'
            name='description'
            className='form-control'
            value={formValues.description}
            onChange={handleChange}
            maxLength={200}
          />
          <span className='help-block hint right'>
            {`${formValues.description?.length || 0}/200`}
          </span>
        </InputWrapper>

        <div className='mb-4'>
          <label className='control-label required d-block mb-2'>
            {t('representation', { scope })}
          </label>

          {renderRepresentationTypeRadioButtons()}
        </div>

        <div className='mt-1 mb-3 lh-base informative-block'>
          <span className='text-danger mr-1'>*</span>
          <span
            className='semi-opaque'
            dangerouslySetInnerHTML={{__html: t('criminal_law_text_html', { scope }) }}
          ></span>
        </div>

        <div className='mt-4 lh-base'>
          <label>
            <input
              type='checkbox'
              className='mr-2'
              name='termsConfirmed'
              checked={formValues.termsConfirmed}
              onChange={handleChange}
            />
            <span className='text-danger mr-1'>*</span>
            <span>{t('terms_confirmed', { scope })}</span>
          </label>
        </div>
      </fieldset>

      <div className='text-center mt-4'>
        {inheritanceClaimPersisted && !isEditing &&
          renderSignableDocumentButtons()
        }
        {(!inheritanceClaimPersisted || isEditing) &&
          renderSaveProjectButton()
        }
        {showSignDocumentModal &&
          <SignDocumentModal
            inheritanceClaim={inheritanceClaim}
            onClose={() => setShowSignDocumentModal(false)}
          />
        }
      </div>
      <Notice {...notice} />
    </div>
  )
}

export { ClaimForm }
